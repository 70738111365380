import { isError } from 'types/predicates';

import createApiClient from 'services/CreateApiClient';

import logSentryError from 'utils/sentry';

import productionScreens from 'dux/dataDrivenProductionFlows/constants/productionScreens.json';
import type { ProductionModeParams } from 'dux/dataDrivenProductionFlows/types/slice-types';
import { savePagination } from 'dux/pagination/slice';

import type { FetchJson } from './types';
import {
  getBody,
  getMethod,
  getRelativeUrl,
  isAuthErrorMessage,
  serializeError,
  stringifyError,
} from './utils';

const mockDataDrivenPayload = (body: ProductionModeParams) => {
  const { selectedProductionCell, selectedViewMode } = body;
  if (selectedViewMode === 'prepack') {
    return selectedProductionCell
      ? {
          json: () =>
            productionScreens.find(item => item.slug === `prepack${selectedProductionCell}`),
        }
      : { json: () => productionScreens.find(item => item.slug === 'prepackhaircare') };
  }
  if (selectedViewMode === 'packing') {
    return selectedProductionCell === 'supplements'
      ? {
          json: () =>
            productionScreens.find(item => item.slug === `packing${selectedProductionCell}`),
        }
      : { json: () => productionScreens.find(item => item.slug === 'packing') };
  }
  const viewMode = productionScreens.find(item => item.slug === selectedViewMode);
  return { json: () => viewMode };
};

const fetchJson: FetchJson =
  ({ baseUrl }) =>
  async (args, api) => {
    const method = getMethod(args);
    const body = getBody(args);
    const baseArg = `${baseUrl}${getRelativeUrl(args)}`;
    const apiClientArgs = method === 'GET' ? [baseArg] : [baseArg, body];

    const callApi = createApiClient(api.dispatch, method);
    try {
      const APIResponse =
        getRelativeUrl(args) !== '/dataDrivenProductionFlows'
          ? await callApi(...apiClientArgs)
          : mockDataDrivenPayload(body as ProductionModeParams);
      const { response, pages } = APIResponse;

      if (pages) {
        api.dispatch(
          savePagination({
            pages,
            callType: api.type,
            endpoint: api.endpoint,
            arg: getRelativeUrl(args),
          })
        );
      }

      // handle status 204 no content
      if (response && response?.status === 204) {
        return { data: {} };
      }

      const payload = await APIResponse.json();
      return { data: payload };
    } catch (error) {
      if (!isError(error)) return { error: null }; // it's always an error so shouldn't get here

      if (!isAuthErrorMessage(stringifyError(error))) {
        logSentryError(`[JSON base query] ${api.endpoint}`, error); // no need to log to Sentry if it's an auth error
      }

      return { error: serializeError(error) };
    }
  };

export default fetchJson;
