import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { Nullable } from 'types/common';

type SliceType = {
  scannedPubkeys: string[];
  completeBinMessage: Nullable<string>;
  lastBufferBinMessage: Nullable<string>;
};

const initialState: SliceType = {
  scannedPubkeys: [],
  completeBinMessage: null,
  lastBufferBinMessage: null,
};

const dataDrivenLocalSlice = createSlice({
  name: 'dataDrivenLocalSlice',
  initialState,
  reducers: {
    addScannedPubkey(draftState, action: PayloadAction<string>) {
      const { payload } = action;
      draftState.scannedPubkeys.push(payload);
    },
    setCompleteBinMessage: (draftState, action: PayloadAction<string>) => {
      const { payload } = action;
      draftState.completeBinMessage = payload;
    },
    setLastBufferBinMessage: (draftState, action: PayloadAction<string>) => {
      const { payload } = action;
      draftState.lastBufferBinMessage = payload;
    },

    clearLocalState: draftState => {
      draftState.completeBinMessage = null;
      draftState.lastBufferBinMessage = null;
      draftState.scannedPubkeys = [];
    },
  },
});

const { reducer, actions } = dataDrivenLocalSlice;

export const { addScannedPubkey, setCompleteBinMessage, setLastBufferBinMessage, clearLocalState } =
  actions;

export default reducer;
