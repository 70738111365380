export const blue = {
  extraLight: '#E3EAF1',
  light: '#3576CB',
  inactiveLight: '#9ABAE5',
  main: '#1D489B',
  secondary: '#365B81',
  dark: '#1F3348',
  inactiveDark: '#8F99A4',
};

export const grey = {
  extraLight: '#F2F2F2',
  light: '#E6E6E6',
  main: '#A6A6A6',
  secondary: '#808080',
  dark: '#6C6C6C',
};

export const yellow = {
  main: '#FFC800',
  inactive: '#F9E0A7',
};

export const red = {
  main: '#C23F38',
  inactive: '#DA8B87',
};

export const green = {
  main: '#03C800',
};

export const orange = {
  main: '#f6ae70',
};

export const black = {
  main: '#161716',
  secondary: '#000000',
  inactive: '#808080',
};

export const white = {
  main: '#ffffff',
};
